/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.pos-modal-root___2s7QE .pos-modal-mask {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9999;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.45);
}
.pos-modal-root___2s7QE .pos-modal-content {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 10000;
  padding: 50px;
  background: #fff;
  border-radius: 3px;
  max-width: calc(100vw - 40px);
  max-height: calc(100vh - 40px);
}
@media (max-width: 575px) {
  .pos-modal-root___2s7QE .pos-modal-content {
    padding: 10px;
  }
}
.pos-modal-root___2s7QE .pos-modal-header {
  text-align: center;
  margin-bottom: 20px;
}
.pos-modal-root___2s7QE .pos-modal-header .anticon {
  font-size: 72px;
}
.pos-modal-root___2s7QE .pos-modal-icon.warn .anticon {
  color: #faad14;
}
.pos-modal-root___2s7QE .pos-modal-icon.info .anticon {
  color: #faad14;
  background: radial-gradient(circle at 50%, #1D1B2E, #1D1B2E 60%, transparent 60%, transparent 100%);
}
.pos-modal-root___2s7QE .pos-modal-icon.error .anticon {
  color: #ff4d4f;
}
.pos-modal-root___2s7QE .pos-modal-icon.success .anticon {
  color: #52c41a;
}
.pos-modal-root___2s7QE .pos-modal-icon.loading .anticon {
  font-size: 42px;
}
.pos-modal-root___2s7QE .pos-modal-body .title {
  font-size: 24px;
  line-height: 34px;
  text-align: center;
  color: #1d1b2e;
}
.pos-modal-root___2s7QE .pos-modal-body .description {
  margin-top: 24px;
  font-size: 20px;
  line-height: 25px;
  text-align: center;
  letter-spacing: -0.3px;
  color: #1d1b2e;
}
.pos-modal-root___2s7QE .confirm-detail-text {
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  text-align: center;
  letter-spacing: -0.301053px;
  color: #1d1b2e;
  margin-top: 10px;
  white-space: pre-line;
}
